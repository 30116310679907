<template>
  <el-row>
    <el-form label-width="120px">
      <el-form-item label="" style="margin-bottom: 5px;">
        <span class="padding-lr-xs">每间隔</span>
        <el-input-number v-model="dataInfo.dayA" size="mini" :step="1" step-strictly @change="refresh()" style="width:100px"></el-input-number>
        <span class="padding-lr-xs">日</span>
        <span class="padding-lr-xs text-bold text-green">可执行</span>,

        <span class="padding-lr-xs">要求</span>
        <el-input-number v-model="dataInfo.dayC" size="mini" :step="1" step-strictly @change="refresh()" style="width:100px"></el-input-number>
        <span class="padding-lr-xs">日</span>
        <span class="padding-lr-xs text-bold text-green">内完成</span>,

        <span class="padding-lr-xs">提前</span>
        <el-input-number v-model="dataInfo.dayB" size="mini" :step="1" step-strictly @change="refresh()" style="width:100px"></el-input-number>
        <span class="padding-lr-xs">日</span>
        <span class="padding-lr-mini text-bold text-blue">生成</span>
        <span class="padding-lr-xs">可执行任务</span>
      </el-form-item>
    </el-form>
  </el-row>
</template>

<script>
export default {
  props: {
    dataInfo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    refresh(reset) {
      this.$emit('refresh', {
        data: this.dataInfo,
        reset: !!reset
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
